export const defaultImage =
  "data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MDIgNTAwIiB2aWV3Qm94PSIwIDAgNTAyIDUwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAwaDUwMHY1MDBoLTUwMHoiIGZpbGw9IiNlOWVlZjEiLz48cGF0aCBkPSJtMzQwLjIgMTk2Ljd2MTA2LjZjMCA5LjgtNy41IDE3LjgtMTYuNyAxNy44aC0xNDQuOWMtOS4yIDAtMTYuNy04LTE2LjctMTcuOHYtMTA2LjZjMC05LjggNy41LTE3LjggMTYuNy0xNy44aDE0NC45YzkuMiAwIDE2LjcgOCAxNi43IDE3Ljh6bS0xNi44IDEwNC40di0xMDIuMmMwLTEuMi0uOS0yLjItMi4xLTIuMmgtMTQwLjdjLTEuMiAwLTIuMSAxLTIuMSAyLjJ2MTAyLjJjMCAxLjIuOSAyLjIgMi4xIDIuMmgxNDAuN2MxLjIgMCAyLjEtMSAyLjEtMi4yem0tMTAzLjEtNzQuOGMwIDguMi02LjIgMTQuOC0xMy45IDE0LjhzLTEzLjktNi42LTEzLjktMTQuOCA2LjItMTQuOCAxMy45LTE0LjggMTMuOSA2LjYgMTMuOSAxNC44em0tMjUgNDEuNSAxMy44LTE0LjZjMS42LTEuNyA0LjMtMS43IDUuOSAwbDEzLjggMTQuNiA0MS42LTQ0LjJjMS42LTEuNyA0LjMtMS43IDUuOSAwbDMwLjUgMzIuNHYyOS42aC0xMTEuNXoiIGZpbGw9IiNiYWJlYzEiLz48L3N2Zz4="

export function validateEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // if the field is empty
  if (!email) {
    return false
  }
  return emailRegex.test(email)
}

export function validatePhone(phone) {
  const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/

  return phoneRegex.test(phone)
}
export function apiUrl(relative) {
  return relative
}

export function formatFullName(obj) {
  return (
    (obj.firstName ? `${obj.firstName} ` : "") +
    (obj.lastName ? `${obj.lastName}` : "")
  )
}
export function isMobile() {
  const navigatorIsMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  const screenIsMobile = window.innerWidth < 768
  return navigatorIsMobile || screenIsMobile
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0")
}

export function formatDate(date, withTime) {
  return withTime
    ? formatDateWithTime(date)
    : `${padTo2Digits(date.getDate())}-${padTo2Digits(
        date.getMonth() + 1
      )}-${date.getFullYear()}`
}

export function formatDateWithTime(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm
  return (
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  )
}

export function formatFullAddress(address) {
  return (
    (address.houseNumber ? `${address.houseNumber} ` : "") +
    (address.streetLine ? `${address.streetLine}, ` : "") +
    (address.district ? `${address.district}, ` : "") +
    (address.postcode ? `${address.postcode}` : "")
  )
}

export function formatFullAddressAsGmapsFormat(address) {
  return (
    (address.houseNumber ? `${address.houseNumber} ` : "") +
    (address.streetLine ? `${address.streetLine}, ` : "") +
    (address.city ? `${address.city}, ` : "") +
    (address.country ? `${address.country}` : "")
  )
}

export function renestByAlphabeticalOrder(data, field = "name") {
  const result = {}
  data.forEach(function (element) {
    if (Array.isArray(result[element[field][0]])) {
      result[element[field][0]].push(element)
    } else {
      result[element[field][0]] = [element]
    }
  })

  return Object.keys(result)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: result[key],
      }),
      {}
    )
}

export function getHelpCentreGuide(contentfulTags) {
  const guides = [
    "installerAccountGuide",
    "installerPresentationGuide",
    "installerQuotingGuide",
    "installerAdministrationGuide",
    "installerHardwareGuide",
    "installerProjectManagementGuide",
    "installerPostInstallationGuide",
    "installerPaymentsGuide",
    "installerLegalGuide",
  ]

  const tag = contentfulTags.find((o) => guides.includes(o.sys.id))
  return tag ? tag.sys.id : undefined
}

export function getHelpCentreGuideColour() {}

export function getHelpCentreGuidePath(contentfulTag) {
  switch (contentfulTag) {
    case "installerAccountGuide":
      return "/help/guides/installers/your-account"
    case "installerPresentationGuide":
      return "/help/guides/installers/presentation"
    case "installerQuotingGuide":
      return "/help/guides/installers/quoting"
    case "installerAdministrationGuide":
      return "/help/guides/installers/administration"
    case "installerHardwareGuide":
      return "/help/guides/installers/hardware"
    case "installerPaymentsGuide":
      return "/help/guides/installers/payments"
    case "installerProjectManagementGuide":
      return "/help/guides/installers/project-management"
    case "installerPostInstallationGuide":
      return "/help/guides/installers/post-installation"
    case "installerLegalGuide":
      return "/help/guides/installers/legal"
  }
}
